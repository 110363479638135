import React from 'react';

interface ISpeedometerProps {
    id: string;
    src: string;
    height: string;
    width: string;
}

export const Speedometer = (props: ISpeedometerProps) => {
    return (
        <div>
            <iframe id={props.id} src={props.src} height={props.height} width={props.width} title={props.id} frameBorder={0} />
        </div>
    );
};
