import React from 'react';
import { IResult } from '../interfaces';

interface IResultProps {
    clientIp: string;
    serverName: string;
    result: IResult;
}

export const Results = (props: IResultProps) => {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div>
            <div className="flex-container">
                <div className="flex-item">
                    <div className="result-container" id="download-speed">
                        <div className="result">
                            <span className="speed">{props.result.downloadSpeed}</span>
                            <span className="unit"> Mbps</span>
                            <div className="title">Nedlasting</div>
                        </div>

                        <p className="description">
                            Nedlasting er når du <b>strømmer</b> film, <b>gamer</b> eller <b>surfer</b> på nettet.
                        </p>
                    </div>
                </div>

                <div className="flex-item">
                    <div className="result-container" id="upload-speed">
                        <div className="result">
                            <span className="speed">{props.result.uploadSpeed}</span>
                            <span className="unit"> Mbps</span>
                            <div className="title">Opplasting</div>
                        </div>

                        <p className="description">
                            Opplasting er når du <b>deler bilder</b> på sosiale medier eller <b>lagrer filer</b> i "skyen".
                        </p>
                    </div>
                </div>
            </div>

            <div className="collapse-container">
                <a className="collapse-button collapsed" data-toggle="collapse" href="#collapse1">
                    Flere detaljer
                    <i className="fa fa-chevron-up" />
                </a>
                <div id="collapse1" className="collapse">
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="detail-result-container">
                                <div className="detail-result">
                                    <span className="speed">{props.result.ping}</span>
                                    <span className="unit"> Ms</span>
                                    <p className="description">
                                        <img
                                            className="detail-image"
                                            src="https://www.altibox.no/wp-content/uploads/2019/08/Ping.svg"
                                            alt="ping"
                                        />
                                        <b>PING</b> - All trafikk sendes frem og tilbake i små datapakker, ping er hvor lang tid denne prosessen tar.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="detail-result-container">
                                <div className="detail-result">
                                    <span className="speed">{props.result.jitter}</span>
                                    <span className="unit"> Ms</span>
                                    <p className="description">
                                        <img
                                            className="detail-image"
                                            src="https://www.altibox.no/wp-content/uploads/2019/08/Jitter.svg"
                                            alt="jitterbilde"
                                        />
                                        <b>JITTER</b> - Høy jitter betyr at ping-tid varierer. Jitter er mest relevant for de som spiller mye.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details-footer">
                        <div className="locations">
                            <div className="ip-address">
                                <div>Din IP-adresse</div>
                                <small>{props.clientIp}</small>
                            </div>
                            <div className="server">
                                <div>Fil lastes fra/til <b>{props.serverName}</b></div>
                                <small>{props.result.server}</small>
                            </div>
                        </div>
                        <div className="progress-line">
                            <img
                                src="https://www.altibox.no/wp-content/uploads/2019/08/Monitor.svg"
                                className="monitor-image"
                                alt="monitor"
                            />
                            <div className="line" />
                            <img
                                src="https://www.altibox.no/wp-content/uploads/2019/08/File_download.svg"
                                className="download-image"
                                alt="nedlasting"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="center btn-container">
                <button className="btn btn-primary" onClick={refreshPage}>Test på ny</button>
            </div>
        </div>
    );
};
