import React from 'react';
import './style/App.scss';
import { Speedometer } from './components/Speedometer';
import { Results } from './components/Results';
import { IResult } from './interfaces';

interface IAppState {
    resultReady: boolean;
    clientIp: string;
    serverName: string;
    result: IResult;
}

export class App extends React.Component<{}, IAppState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            resultReady: false,
            clientIp: '',
            serverName: '',
            result: {
                server: '',
                downloadSpeed: 0,
                uploadSpeed: 0,
                ping: 0,
                jitter: 0,
            },
        };
    }

    public componentDidMount = () => {
        window.addEventListener('message', this.handleResult, false);
    };

    public render = () => {
        return (
            <div className="App">
                {this.state.resultReady ?
                    <Results result={this.state.result} clientIp={this.state.clientIp} serverName={this.state.serverName} /> :
                    <Speedometer
                        id="speedtestiframe"
                        width="100%"
                        height="600px"
                        src="https://altibox.ipv4-only.speedtestcustom.com/"
                    />
                }
            </div>
        );
    };

    private serverToCity = (server: string) => {
        switch (server) {
            case 'boo-jern-speedtest1.altibox.net':
                return 'Bodø';
            case 'osl-ulv-speedtest1.altibox.net':
                return 'Oslo';
            case 'stv-gm-speedtest1.altibox.net':
                return 'Stavanger';
            default:
                return server;
        }
    };

    private handleResult = (event: MessageEvent) => {
        if (!event.origin || event.origin !== 'https://altibox.ipv4-only.speedtestcustom.com') {
            return;
        }

        const down = event.data.download / 1000;
        const up = event.data.upload / 1000;

        const newResult: IResult = {
            server: this.serverToCity(event.data.config.host),
            downloadSpeed: parseFloat(down.toFixed(1)),
            uploadSpeed: parseFloat(up.toFixed(1)),
            ping: event.data.latency.minimum,
            jitter: event.data.latency.jitter.toFixed(0),
        };

        fetch('https://speedoapi.altibox.no/api/result', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(event.data),
        });

        const urls = [
            'https://speedoapi.altibox.no/api/ip',
            'https://speedoapi.altibox.no/api/asn',
        ];

        Promise.all(urls.map(url => {
            return fetch(url).then(response => response.json());
        })).then((values) => {
            this.setState({ resultReady: true, result: newResult, clientIp: values[0].ip, serverName: values[1].as_description });
        });
    };
}
